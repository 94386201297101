import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

const PopularArticles = () => {
  const theme = useTheme();
  const [articles, setArticles] = useState([]); // Initialize articles state as an empty array
  const options = { day: '2-digit', month: 'short' };
  const images = [
    'https://assets.maccarianagency.com/backgrounds/img16.jpg',
    'https://assets.maccarianagency.com/backgrounds/img18.jpg',
    'https://assets.maccarianagency.com/backgrounds/img17.jpg',
    'https://assets.maccarianagency.com/backgrounds/img13.jpg',
    'https://assets.maccarianagency.com/backgrounds/img14.jpg',
  ];

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('https://cms.cognijiwa.ai/items/blogs?fields=*.*&filter={"status": {"_eq": "published"}}&sort[]=-date_created'); // Replace YOUR_API_ENDPOINT with your actual API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch articles');
        }
        const data = await response.json();
        setArticles(data['data']);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []); // The empty dependency array ensures this effect runs once on component mount

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={'h6'} gutterBottom>
            Popular articles
          </Typography>
          <Typography color={'text.secondary'}>
            Here’s what we’ve been up to recently.
          </Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {articles.map((item, i) => (
          <Grid item xs={12} sm={i === 0 ? 12 : 6} md={i < 2 ? 6 : 4} key={i}>
            <Box
              component={'a'}
              href={`/blog/${item.id}`}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                <CardMedia
                  image={item['thumbnail'] && item['thumbnail'].id ? 'https://cms.cognijiwa.ai/assets/'+item['thumbnail'].id : ''}
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    position: 'relative',
                    backgroundPosition: 'top',
                  }}
                >
                  <Box
                    component={'svg'}
                    viewBox="0 0 2880 480"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      color: theme.palette.background.paper,
                      transform: 'scale(2)',
                      height: 'auto',
                      width: 1,
                      transformOrigin: 'top center',
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                      fill="currentColor"
                    />
                  </Box>
                </CardMedia>
                <Box component={CardContent} position={'relative'}>
                  <Typography variant={'h6'} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box padding={2} display={'flex'} flexDirection={'column'}>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Avatar
                        src={'https://cms.cognijiwa.ai/assets/'+item['editor'].icon}
                        sx={{ marginRight: 1 }}
                      />
                      <Typography color={'text.secondary'}>
                        {item['editor'].name}
                      </Typography>
                    </Box>
                    <Typography color={'text.secondary'}>
                      {new Date(item.date_created).toLocaleDateString('en-GB', options)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
        {/*<Grid item container justifyContent={'center'} xs={12}>*/}
        {/*  <Pagination count={10} size={'large'} color="primary" />*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  );
};

export default PopularArticles;
