import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import DOMPurify from 'dompurify';
import content from '../../../BlogArticle/components/Content';

// Assuming the initial state is null or an appropriate loading state
const FeaturedArticle = () => {
  const theme = useTheme();
  const [article, setArticle] = useState(null); // State to store fetched data
  const [cleanHTML, setCleanHTML] = useState(''); // Use state to store the sanitized HTML
  const options = { day: '2-digit', month: 'short' };

  useEffect(() => {
    // Fetch the article data when the component mounts
    const fetchData = async () => {
      try {
        const response = await fetch('https://cms.cognijiwa.ai/items/blogs?fields=*.*&filter={"status": {"_eq": "published"}}&sort[]=is_highlight_content&limit=1'); // Replace 'YOUR_ENDPOINT_URL' with your actual endpoint URL
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setArticle(data['data']); // Update state with fetched data
        // Move sanitization here, ensure article data is loaded
        if (data['data'] && data['data'][0] && data['data'][0].content) {
          let sanitizedContent = content; // Default to non-sanitized content for SSR
          if (typeof window !== 'undefined') {
            sanitizedContent = DOMPurify.sanitize(data['data'][0].content.substring(0, 100) + '...');
          }
          setCleanHTML(sanitizedContent);
        }
      } catch (error) {
        console.error('Failed to fetch article data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  if (!article) {
    return <div>Loading...</div>; // Loading state or spinner can go here
  }

  return (
    <Box>
      <Box
        component={'a'}
        href={`/blog/${article[0].id}`}
        display={'block'}
        width={1}
        height={1}
        sx={{
          textDecoration: 'none',
          transition: 'all .2s ease-in-out',
          '&:hover': {
            transform: `translateY(-${theme.spacing(1 / 2)})`,
          },
        }}
      >
        <Box
          component={Card}
          width={1}
          height={1}
          boxShadow={4}
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row-reverse' }}
          sx={{ backgroundImage: 'none' }}
        >
          <Box
            sx={{
              width: { xs: 1, md: '50%' },
              position: 'relative',
            }}
          >
            <Box
              component={'img'}
              loading="lazy"
              height={1}
              width={1}
              src={'https://cms.cognijiwa.ai/assets/'+article[0]['thumbnail'].id}
              alt="..."
              sx={{
                objectFit: 'cover',
                objectPosition: '20% 26%',
                maxHeight: 360,
                marginBottom:-1,
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              }}
            />
            <Chip
              label="Featured"
              sx={{
                position: 'absolute',
                top: 20,
                right: 20,
                bgcolor: 'background.paper',
              }}
            />
          </Box>
          <CardContent
            sx={{
              position: 'relative',
              width: { xs: 1, md: '50%' },
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant={'h5'} gutterBottom sx={{fontWeight:'600'}}>
                {article[0].title}
              </Typography>
              <Typography
                color="text.secondary"
                component="div" // Change the component prop to div to properly render inner HTML
                dangerouslySetInnerHTML={{ __html: cleanHTML }}
              >
              </Typography>
            </Box>
            <Box>
              <Divider sx={{ marginY: 2 }} />
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <Avatar src={'https://cms.cognijiwa.ai/assets/'+article[0]['editor'].icon} sx={{ marginRight: 1 }} />
                  <Typography color={'text.secondary'}>
                    {article[0]['editor'].name}
                  </Typography>
                </Box>
                <Typography color={'text.secondary'}>
                  {new Date(article[0].date_created).toLocaleDateString('en-GB', options)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedArticle;
